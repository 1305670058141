<template>
    <v-content>
        <v-layout align-center justify-center style="padding-top: 10%;">
            <v-card class="elevation-0"
                    :style="{width: isMobile ? '90%' : '500px'}"
                    style="border-radius: 20px; border: 1px solid #E0E0E0; box-sizing: border-box;"
                    light>
                <v-card-title class="font-weight-bold display-1"
                              style="padding: 40px 0 0 40px;">
                    <label>Вход</label>
                </v-card-title>
                <v-card-text style="padding: 10px 40px 0 40px;">
                    <v-form ref="form" validation>
                        <v-layout align-center justify-space-between>
                            <label class="subtitle-1">Почта</label>
                            <v-text-field name="login"
                                          type="text"
                                          style="max-width: 60%"
                                          :rules="loginRules"
                                          color="secondary"
                                          v-model.trim="login"
                                          @input="error = false"
                                          autocomplete="new-login"
                                          required/>
                        </v-layout>

                        <v-layout align-center justify-space-between>
                            <label class="subtitle-1">Пароль</label>
                            <v-text-field id="password"
                                          name="password"
                                          type="password"
                                          style="max-width: 60%"
                                          color="secondary"
                                          :rules="passwordRules"
                                          v-model.trim="password"
                                          @input="error = false"
                                          autocomplete="new-password"
                                          @keypress.enter="onSubmit"
                                          required/>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-slide-y-transition>
                    <v-layout align-center justify-center v-show="error">
                        <label class="text--secondary" style="text-align: center;">
                            <v-icon color="secondary">mdi-information</v-icon>
                            {{ text }}
                        </label>
                    </v-layout>
                </v-slide-y-transition>
                <v-card-actions style="padding-left: 30px; padding-bottom: 20px;">
                    <v-layout column align-center justify-center>
                        <label class="recovery-link"
                               style="font-weight: 500;"
                               @click="isVisibleRecoveryDialog = true">Забыли пароль?</label>
                        <v-btn color="secondary"
                               @click="onSubmit"
                               style="width: 150px; border-radius: 10px; height: 43px;"
                               class="font-weight-regular entry-button">Войти
                        </v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-layout>

        <v-dialog v-model="isVisibleRecoveryDialog"
                  :persistent="resetLoading"
                  class="elevation-0"
                  overlay-color="white"
                  width="530">
            <v-card style="padding: 20px; border-radius: 20px;" class="elevation-1">
                <v-card-title class="display-1">
                    Восстановление пароля
                </v-card-title>
                <v-card-text style="padding-bottom: 0; padding-left: 25px; padding-top: 10px;">
                    <v-form ref="validateForm" @submit.prevent="reset">
                        <v-layout align-center justify-space-between="">
                            <label class="subtitle-1">Почта</label>
                            <v-text-field name="login"
                                          type="text"
                                          :rules="recoveryRules"
                                          color="secondary"
                                          style="max-width: 70%;"
                                          v-model.trim="resetEmail"
                                          @input="error = false"
                                          validate-on-blur
                                          autocomplete="new-login"
                                          required/>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions style="padding-top: 0;">
                    <v-layout justify-center>
                        <v-btn color="secondary"
                               @click="reset"
                               style="width: 200px; border-radius: 8px; height: 43px; margin-bottom: 10px;"
                               :loading="resetLoading"
                               class="font-weight-regular entry-button">Отправить ссылку</v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" color="secondary" :timeout="2000" top>
            <v-icon dark>mdi-check</v-icon>
            <label>Ссылка отправлена на указанную почту.</label>
        </v-snackbar>

        <v-snackbar v-model="errorSnackbar" color="error" :timeout="2000" top>
            <v-layout justify-center>
                <v-icon dark>mdi-information</v-icon>
                <label style="margin-left :10px;">{{recoveryErrorText}}</label>
            </v-layout>
        </v-snackbar>
    </v-content>
</template>

<script>
    let shajs = require('sha.js');

    export default {
        name: "LoginView",
        data() {
            return {
                login: '',
                password: '',
                loginRules: [v => !!v && !!v.trim() || 'Введите почту'],
                passwordRules: [v => !!v && !!v.trim() || 'Введите пароль'],
                recoveryRules: [
                    v => !!v && !!v.trim() || 'Введите почту',
                    v => {
                        let r = /^[A-Z0-9a-z.%+\-_]+@[A-Za-z0-9]+\.[A-Za-z]{2,64}$/
                        let match = v.match(r)
                        console.log(match, v)
                        return !!v && match && match[0] === v.trim() || 'Неверный формат почты'
                    }
                ],
                error: false,
                text: '',
                isVisibleRecoveryDialog: false,
                resetEmail: '',
                resetLoading: false,
                snackbar: false,
                errorSnackbar: false,
                recoveryErrorText: null
            }
        },
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            },
            height() {
                return this.$store.state.height
            }
        },
        watch: {
            isVisibleRecoveryDialog() {
                this.$nextTick().then(() => {
                    this.$refs.form.resetValidation();
                    this.$refs.validateForm.resetValidation();
                    this.resetEmail = ''
                })
            }
        },
        methods: {
            onSubmit() {
                if (this.$refs.form.validate()) {
                    const postData = {
                        login: this.login,
                        passwordHash: shajs('sha256').update(this.password).digest('hex')
                    };

                    console.log(postData, this.$http);
                    this.$http.post('/profile/entry', postData)
                        .then(response => {
                            console.log('response', response)
                            localStorage.setItem('token', response.data.token);
                            this.$http.defaults.headers.common["token"] = response.data.token;
                            this.$store.dispatch('setAdminData', response.data.data);
                            this.$router.history.push({name: 'home'});
                        }).catch(error => {
                        console.log(error)
                        this.error = true;
                        if (error.response && error.response.data.message) {
                            this.text = error.response.data.message
                        } else
                            this.text = "Ошибка входа"
                    })
                }
            },
            reset() {
                if (this.$refs.validateForm.validate()) {
                    this.resetLoading = true;
                    const postData = {
                        login: this.resetEmail
                    };

                    this.$http.post('/profile/recovery', postData)
                        .then(response => {
                            this.isVisibleRecoveryDialog = false;
                            this.snackbar = true
                        }).catch(error => {
                        switch (error.response.status) {
                            default:
                                this.errorSnackbar = true;
                                this.recoveryErrorText = 'Ошибка отправки';
                                break
                        }
                    }).finally(() => {
                        this.resetLoading = false
                    })
                }
            }
        },
        mounted() {
            localStorage.removeItem('token');
            this.$http.defaults.token = null
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';
    $dialog-elevation: 1;

    .entry-button {
        margin-top: 15px;
    }

    .recovery-link {
        color: #626262;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 10px;
    }

    .recovery-link:hover {
        color: #929292;
    }

    .login-content {
        padding-top: 10%;
    }
</style>